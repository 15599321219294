import React from "react";
export default function Home() {
  return (
    <>
      <main>
        <h1>Dit is home.</h1>
      </main>
    </>
  );
}
